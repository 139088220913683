<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <div class="form_item">
            <div class="form_label">作者</div>
            <Input class="width_500" v-model.trim="formData.username" :maxlength="30" show-word-limit />
        </div>
        <div class="form_item">
            <div class="form_label must_input">标题</div>
            <Input class="width_500" v-model.trim="formData.title" :maxlength="30" show-word-limit />
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">内容</div>
            <editor v-model.trim="formData.note"></editor>
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">状态</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">有效</Radio>
                <Radio label="0">无效</Radio>
            </RadioGroup>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start">缩略图</div>
            <uploadImageMultiple :width="100" :height="100" v-model="picArr" :len="3"></uploadImageMultiple>
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">跳转类型</div>
            <RadioGroup v-model="formData.sow" type="button" button-style="solid">
                <Radio label="0">原创</Radio>
                <Radio label="1">图文</Radio>
            </RadioGroup>
        </div>
        <div v-if="formData.sow == 1" class="form_item">
            <div class="form_label must_input">图文链接</div>
            <Input class="width_500" v-model.trim="formData.weChat_url" :maxlength="100" show-word-limit />
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { mapGetters } from 'vuex';
import editor from '@/components/editor';
import { reqSaveNews } from '@/lib/request/auth2';
import uploadImageMultiple from '@/components/uploadImageMultiple';
import localstorage from '@/lib/localstorage';
import { SET_USERINFO } from '@/store/mutationsType';

export default {
    name: 'informationAdd',
    components: {
        editor,
        uploadImageMultiple,
    },
    data() {
        return {
            title: null,
            pic: null,
            picArr: [],
            formData: {
                id: null,
                username: null,
                title: null,
                note: null,
                status: '1',
                sow: '0',
                weChat_url: null,
            },
            isEdit: false,
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['pic']);
        this.title = this.isEdit ? '编辑' : '添加';
        if (this.isEdit) {
            if (!!this.pic) {
                this.picArr = this.pic.split(',');
            }
        } else {
            this.formData.username = this.getUserInfo().nickName;
        }
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    watch: {
        //
    },
    methods: {
        getUserInfo() {
            return Object.keys(this.userInfo || {}).length == 0 ? localstorage.get(SET_USERINFO) || {} : this.userInfo;
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            window.setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { title, note, weChat_url, sow } = params || {};
            let vs = [
                { value: title, tip: '请输入标题' },
                { value: note, tip: '请输入内容' },
            ];
            if (this.picArr.length > 0) {
                params.pic = this.picArr.join(',');
            }
            if (sow == 1 && !!!weChat_url) {
                this.fadeWarning('请输入图文链接');
                return;
            }
            hm.validateForm(vs)
                .then(() => {
                    this.showAjaxLoading();
                    reqSaveNews(params)
                        .then(res => {
                            this.fadeAlert('操作成功');
                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
