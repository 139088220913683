<template>
    <div class="relative flex flex_wrap align_center upload_container">
        <Progress v-if="progress.isShow" :percent="progress.value" hide-info></Progress>

        <div v-for="(src, idx) in srcValues" :key="'upload_item_' + idx" class="relative pic_container" :style="{ width: width + 'px', height: height + 'px' }">
            <img class="pervSrc" :src="src" :style="{ width: width + 'px', height: height + 'px' }" />
            <div class="pages pointer_events_none item_border"></div>
            <div class="upload-cover flex align_center justify_center" v-if="manager">
                <Icon type="ios-eye-outline" @click.stop="handleView(idx)"></Icon>
                <Icon v-if="!isDisabled" type="ios-trash-outline" @click.stop="handleRemove(idx)"></Icon>
            </div>
        </div>
        <Upload
            v-if="srcValues.length < len"
            class="block relative"
            :disabled="isDisabled"
            :show-upload-list="false"
            :max-size="maxSize"
            :format="format"
            type="drag"
            :action="action"
            :data="formData"
            :style="{ width: width + 'px', height: height + 'px' }"
            :on-success="res => handleSuccess(res, srcValues.length)"
            :on-error="err => handleError(srcValues.length, err)"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            :on-progress="onProgress"
        >
            <div :style="{ width: width + 'px', height: height + 'px', lineHeight: height + 'px' }">
                <Icon type="ios-add" size="27"></Icon>
            </div>
            <div class="limit_tip">单图不能超过{{ Math.ceil(this.maxSize / 1024) }}M</div>
        </Upload>
        <div class="tip_txt">(最多上传{{ len }}张)</div>

        <Modal draggable title="图片预览" v-model="visible" :width="600" class="relative">
            <div style="margin: 0 auto; overflow: auto; width: 100%; height: 500px">
                <img :src="previewImg" />
            </div>
        </Modal>
    </div>
</template>
<script>
// import { uploadToken } from '@/lib/request/upload';

export default {
    name: 'uploadImage',
    props: {
        value: null,
        width: {
            type: Number,
            required: false,
            default: 200,
        },
        height: {
            type: Number,
            required: false,
            default: 200,
        },
        manager: {
            type: Boolean,
            required: false,
            default: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        len: {
            type: Number,
            required: false,
            default: 3,
        },
    },
    data() {
        return {
            maxSize: 2048, //单位 kb
            format: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'],
            action: window.location.origin + '/commonUpload/ajax_up',
            formData: {
                upfun: 'admin',
            },
            srcValues: [],
            previewImg: null,
            dir: 'qilang',
            fileName: null,
            visible: false,
            progress: {
                isShow: false,
                value: 0,
            },
        };
    },
    watch: {
        value(newValue) {
            this.srcValues = newValue;
        },
    },
    mounted() {
        this.srcValues = this.value;
        if (process.env.NODE_ENV === 'development') {
            this.action = window.location.origin + '/upload/commonUpload/ajax_up';
        }
        // this.reqUploadToken();
    },
    methods: {
        reqUploadToken() {
            this.ajaxLoading = true;
            uploadToken().then(res => {
                // console.log('reqUploadToken response res = ', res);
                this.ajaxLoading = false;
                let { session_token, tmp_secret_id, tmp_secret_key } = res.data || {};
                let authorization = window.CosAuth({
                    SecretId: tmp_secret_id,
                    SecretKey: tmp_secret_key,
                    Method: 'POST',
                    Pathname: '/',
                });
                this.formData['Signature'] = authorization;
                this.formData['x-cos-security-token'] = session_token;
            });
        },
        handleView(idx) {
            this.previewImg = this.srcValues[idx];
            this.visible = true;
        },
        handleRemove(idx) {
            this.srcValues.splice(idx, 1);
            console.log('handleRemove', this.srcValues);
            this.$emit('input', this.srcValues);
            this.$emit('remove');
        },
        handleSuccess(res, idx) {
            this.progress.isShow = false;
            // let src = `${this.action}/${this.dir}/${this.fileName}`;
            let src = (res.split(',') || [])[1] || null;

            this.srcValues[idx] = src;
            console.log('handleSuccess', this.srcValues);
            this.$emit('input', this.srcValues);
            this.$emit('success');
        },
        handleError(idx, err) {
            console.log('handlerError', idx, err);
            this.srcValues[idx] = null;
            this.progress.isShow = false;
            this.fadeWarning('上传异常');
            // this.reqUploadToken();
        },
        handleFormatError(file) {
            this.progress.isShow = false;
            this.fadeWarning(`文件格式必须为${this.format.join('、')}`);
        },
        handleMaxSize() {
            this.progress.isShow = false;
            this.fadeWarning(`文件大小不能超过${Math.ceil(this.maxSize / 1024)}M`);
        },
        handleBeforeUpload(evt) {
            this.progress.isShow = true;
            this.progress.value = 0;
            let name = evt.name;
            let [prevName, diffName] = name.split('.');
            this.fileName = `${prevName}_${hm.getTime()}.${diffName}`;
            this.formData['key'] = `${this.dir}/${this.fileName}`;
            return true;
        },
        onProgress(event) {
            this.progress.value = event.percent;
        },
    },
};
</script>
<style scoped lang="less">
.upload_container {
    width: 100%;
    height: 100%;
    gap: 10px;
    .limit_tip {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        text-align: center;
        font-size: 9px;
        color: rgba(0, 0, 0, 0.6);
    }
}
.upload_container /deep/ .ivu-upload-drag {
    width: 100%;
    height: 100%;
}

.pic_container {
    .item_border {
        border: 1px dashed #dcdee2;
        border-radius: 4px;
    }
    .pervSrc {
        object-fit: cover;
    }
    .upload-cover {
        position: absolute;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        transition: opacity 0.4s;
        i {
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 0 2px;
        }
    }
    &:hover .upload-cover {
        opacity: 1;
    }
}
</style>
